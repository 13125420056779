import React from "react";
import {Link} from "gatsby";
import course1 from "../assets/images/course-1-1.jpg";
import useProjects from "../Hooks/useProjects";
import Img from "gatsby-image";

const Projects = () => {
    const projects = useProjects()
    return (
        <section className="course-one course-page">
            <div className="container">
                <div className="row">
                    {projects.map(project =>
                        <div key={project.id} className="col-lg-4">
                            <div className="course-one__single">
                                <div className="course-one__image">
                                    <Img fluid={{ ...project.image.localFile.sharp.fluid }} alt={project.title} />
                                </div>
                                <div className="course-one__content">
                                    <Link to={`/${project.slug}`} className={`course-one__category rol ${project.project_type.identifier}`}>
                                        {project.project_type.type}
                                    </Link>
                                    <h2 className="course-one__title">
                                        <Link to="/course-details">{project.title}</Link>
                                    </h2>
                                    <Link to={`/${project.slug}`} className="course-one__link">
                                        Ver
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Projects;
