import React from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NavThree from "../components/NavThree";
import Projects from "../components/Projects";
import CourseCatThree from "../components/CourseCatThree";
import CallToActionFive from "../components/CallToActionFive";
import CallToActionFour from "../components/CallToActionFour";

const Page404 = () => {
    return (
        <Layout pageTitle="404 | Not Found">
            <NavThree active={""}/>
            <PageHeader title="404"/>
            <CallToActionFour/>
            <CallToActionFive/>
            <Footer/>
        </Layout>
    );
};

export default Page404;
